<template>
  <v-parallax
    src="@/assets/paralax/paralax.jpg"
  >
    <div class="d-flex flex-column-reverse flex-md-row justify-md-end align-md-center pt-6 pb-6">
      <v-col cols="12"
            md="4">
        <h3 class="text-right d-md-none custom-color">
          "My works, discoveries, receipts - my days - everyday life of one icon painter."
        </h3>
        <h1 class="text-right d-none d-md-block custom-color">
          "My works, discoveries, receipts - my days - everyday life of one icon painter."
        </h1>
      </v-col>
      <v-col cols="12"
            md="6">
      <CarouselComponent 
                        :slides="slides"
                        :show-arrow="false"/>
      </v-col>
    </div>
  </v-parallax>
  <div class="d-flex align-center flex-wrap justify-space-between justify-lg-center flex-column flex-md-row pa-4 w-100">
    <template v-for="(info, i) in homeViewInfo" :key="i">
      <v-col cols="12">
        <h1 class="font-weight-bold text-center">
          {{ info.title }}
        </h1>
        <h3 class="font-italic font-weight-medium text-center">
          {{ info.subtitle }}
        </h3>
      </v-col>
      <v-col cols="12"
              md="3"
              v-for="(photo, p) in info.photos" :key="p">
        <v-img
          height="400px"
          :src="require(`@/assets/${photo.src}`)"
          :alt="photo.alt"
          cover
        ></v-img>
      </v-col>
    </template>
  </div>
</template>

<script setup>
import CarouselComponent from '@/_shared/CarouselComponent.vue';

import { slides } from '@/data/carouselSlides';
import { homeViewInfo } from '@/data/homeViewInfo'

import { ref, computed, onMounted } from 'vue';

const homeViewInfoData = ref(homeViewInfo);

const jsonLDScript = computed(() => {
  return homeViewInfoData.value.flatMap(info => {
    return info.photos.map(photo => {
      return {
        "@context": "http://schema.org",
        "@type": "ImageObject",
        "contentUrl": `https://ksenon-icons.com.ua/assets/${photo.src}`,
        "description": photo.alt
      };
    });
  });
});

const stringifiedJsonLDScript = computed(() => {
  const scripts = jsonLDScript.value.map(obj => JSON.stringify(obj));
  return `[${scripts.join(',')}]`; // Wrap the array of objects with square brackets to make it a valid JSON array
});

// Function to append JSON-LD script to document head
const appendJsonLDScript = () => {
  const script = document.createElement('script');
  script.type = 'application/ld+json';
  script.text = stringifiedJsonLDScript.value;
  document.head.appendChild(script);
};

// Call the function when the component is mounted
onMounted(() => {
  appendJsonLDScript();
});
</script>


<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'HomeView',
    components: {
      CarouselComponent
    }
  });
</script>

<style scoped>
.custom-color{
  color:#f0fffb;
  font-style: italic;
}
</style>

