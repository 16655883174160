import { createRouter, createWebHistory } from 'vue-router';
import HomeView from './view/HomeView.vue';
import IconView from './view/IconView.vue';
import ChurchPaintingView from './view/ChurchPaintingView.vue'
import ContactView from './view/ContactView.vue'
import SocialActivitiesView from './view/SocialActivitiesView.vue'

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/icon',
    name: 'IconView',
    component: IconView
  },
  {
    path: '/church-painting',
    name: 'ChurchPaintingView',
    component: ChurchPaintingView 
  },
  {
    path: '/social-activities',
    name: 'SocialActivitiesView',
    component: SocialActivitiesView
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: ContactView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
