<template>
  <div class="d-flex align-center flex-wrap justify-space-between justify-lg-center flex-column flex-md-row pa-4 w-100">
    <template v-for="(photoTexture, i) in photoTextures" :key="i">
      <v-col cols="12" 
              md="8"
              lg="9"
              >
        <div  v-if="isMobile"
              class="custom-class">
          <img :src="`assets/${photoTexture.photoTextureMobile}`" 
                alt="mobile photo"
                class="w-100">
        </div>
        <SceneComponent v-else
            :photo-texture="photoTexture.photoTexture" />
      </v-col>
      <v-col cols="12" 
              md="2"
              class="">
        <v-btn
          append-icon="mdi-open-in-new" 
          variant="tonal"
          color="#3fafb3"
          size="large"
          @click="openSlider(photoTexture.id)"
        >
          More Photos
        </v-btn>
      </v-col>
      <v-row class="justify-start align-center">
        <v-col cols="12" 
                md="4"
                class="mr-0 pr-0 ml-md-11 pl-md-10">
          <video controls width="390">
            <source :src="require('@/assets/video_audio.mp4')" type="video/mp4">
            Your browser does not support this video.
          </video>
        </v-col>
        <v-col cols="12"
                md="7"
                class="mr-0 pr-0 mr-md-11 pr-md-10">
          <p class="font-italic text-h6">
            &nbsp;&nbsp;&nbsp;&nbsp;{{ photoTexture.text }}
          </p>
        </v-col>
      </v-row>
      <template v-for="(addInfo, a) in photoTexture.add_info" :key="a">
        <v-col cols="12">
          <p class="font-weight-bold text-center">
            {{ addInfo.text }}
          </p>
        </v-col>
        <v-col cols="12"
                md="3"
                v-for="(photo, p) in addInfo.photos" :key="p">
          <v-img
            height="400px"
            :src="require(`@/assets/${photo.src}`)"
            cover
          ></v-img>
        </v-col>
      </template>
    </template>
  </div>
  <div v-if="isSliderOpen">
      <CarouselOverlayComponent :cards="photoTextures" />
  </div>
</template>

<script setup>
import SceneComponent from '@/_shared/SceneComponent.vue'
import CarouselOverlayComponent from '@/_shared/CarouselOverlayComponent.vue'

import { useStore } from 'vuex'

import { computed, ref } from 'vue'

import { photoTextures } from '@/data/photoTextures'

const store = useStore()

const openSlider = (cardId) => {
  store.commit('SET_CURRENT_CARD_ID', cardId)
  store.commit('SET_SLIDER_OPEN', true)
  store.commit('SET_OVERLAY_OPEN', true)
}

const isMobile = ref(window.innerWidth <= 768);

const isSliderOpen = computed(() => {
  return store.getters.isSliderOpen
})
</script>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChurchPaintingView',
  components: {
    SceneComponent,
    CarouselOverlayComponent,
  },
})
</script>

<style scoped>
/* .custom-class{

  @media (min-width: 768px){
    overflow: none;
  }
} */
</style>
