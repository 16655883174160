<template>
<div class="w-100">
    <v-row v-for="(row, index) in cardRows" 
            :key="index"
            class="ma-1">
      <v-col cols="12"
        md="3"
        v-for="(card, i) in row"
        :key="i">
        <v-skeleton-loader
              :loading="loading"
              height="400"
              type="image, list-item-two-line"
            >
        <v-card
          class="mx-auto"
          
        >
          <v-img
            height="400px"
            :src="require(`@/assets/${card.img}`)"
            :alt="`${card.title}`"
            cover
          ></v-img>

          <v-card-title>
            {{ card.title }}
          </v-card-title>

          <v-card-subtitle>
            {{ card.subtitle }} <br>
            {{ card.subtitle_add }}
          </v-card-subtitle>

          <v-card-text>
            <div v-html="isFullTextShown(card.id) ? card.text : truncatedText(card, i)"></div>
            <v-btn v-if="showMoreButton(card) || isFullTextShown(card.id)"
                  @click="toggleShowFullText(card.id)"
                  color="#3fafb3"
                  variant="tonal">
              {{ isFullTextShown(card.id) ? 'Less' : 'More' }}
            </v-btn>
          </v-card-text>


          <v-card-title v-if="card.price">
            {{ card.price }} &euro;
          </v-card-title>

          <v-card-actions>
            <v-btn
              color="#3fafb3"
              variant="tonal"
              @click="openSlider(card.id)"
            >
              More Photos
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
                density="comfortable"
                size="large"
                variant="tonal"
                color="#3fafb3"
                icon
                @click="toggleDropdown(i)"
              >
                <v-icon>{{ isDropdownVisible(i) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div>
                <div v-show="isDropdownVisible(i)">
                  <v-divider></v-divider>
                    <template v-for="(description, i) in card.descriptions" :key="i">
                      <v-card-text>
                      {{ i.toUpperCase() }}: {{ description }}
                      </v-card-text>
                    </template>
                </div>
            </div>
          </v-expand-transition>
        </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>
</div>
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  cards: Array
});

const cardRows = ref([]);

const groupCards = () => {
  const slicedCards = [];
  for (let i = 0; i < props.cards.length; i += 4) {
    slicedCards.push(props.cards.slice(i, i + 4));
  }
  cardRows.value = slicedCards;
};

const store = useStore();

const dropdownVisibility = ref([]);

const loading = ref(true);

const toggleDropdown = (index) => {
  dropdownVisibility.value[index] = !dropdownVisibility.value[index];
};

const openSlider = (cardId) => {
  store.commit('SET_CURRENT_CARD_ID', cardId);
  store.commit('SET_SLIDER_OPEN', true);
  store.commit('SET_OVERLAY_OPEN', true);
};

const isDropdownVisible = (index) => {
  return dropdownVisibility.value[index];
};

const showFullTextStates = ref({});

const toggleShowFullText = (cardId) => {
  showFullTextStates.value[cardId] = !showFullTextStates.value[cardId];
};

const isFullTextShown = (cardId) => {
  return !!showFullTextStates.value[cardId];
};

const truncateText = (text) => {
  if (text.length > 100) {
    return text.slice(0, 100) + '...';
  } else {
    return text;
  }
};

const truncatedText = (card, cardId) => {
  if (isFullTextShown(cardId)) {
    return card.text; 
  } else {
    return truncateText(card.text); 
  }
};

const showMoreButton = (card) => {
  return !isFullTextShown(card.id) && card.text.length > 100;
};

onMounted(() => {
  groupCards();
  loading.value = false;
});
</script>

<style scoped>
::v-deep .v-btn--elevated {
    margin-top: 0.5rem;
    width: 100vw;
}

::v-deep .v-img__img--cover{
  object-fit: contain;
  background-color: black;
}
</style>
