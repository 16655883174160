<template>
  <div class="custom-width">
    <!-- Facebook Page Plugin -->
    <div class="fb-page" 
         data-href="https://www.facebook.com/profile.php?id=100017179511861" 
         data-tabs="timeline" 
         data-width="500px" 
         data-height="600px" 
         data-small-header="false" 
         data-adapt-container-width="true" 
         data-hide-cover="true" 
         data-show-facepile="true">
      <blockquote cite="https://www.facebook.com/profile.php?id=100017179511861" class="fb-xfbml-parse-ignore">
        <a href="https://www.facebook.com/profile.php?id=100017179511861">Kseniia Bludova</a>
      </blockquote>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  if (window.FB) {
    window.FB.XFBML.parse();
  }
});
</script>


<style scoped>
.custom-width {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.fb-page {
  width: 100% !important;
  height: auto;
}
</style>