<template>
  <div id="app">
    <MainViewHeader/>
    <v-container class="pa-0 customer-styles">
      <router-view />
    </v-container>
    <MainViewFooter/>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import MainViewHeader from '/src/components/MainViewHeader.vue';
import MainViewFooter from '/src/components/MainViewFooter.vue';

const router = useRouter();

const redirectToHomeIfNeeded = () => {
  if (sessionStorage.getItem('hasVisited') === 'true') {
    router.replace('/'); 
  }
};

onMounted(() => {
  if (!sessionStorage.getItem('hasVisited')) {
    sessionStorage.setItem('hasVisited', 'true');
  }

  redirectToHomeIfNeeded();
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/') {
    sessionStorage.setItem('hasVisited', 'true');
  }
  next();
});
</script>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'App',
    components: {
      MainViewHeader,
      MainViewFooter,
    }
  });
  
</script>


<style>
/* Import Montserrat font files */
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('@/assets/fonts/Montserrat-Regular.ttf') format('ttf'),
        url('@/assets/fonts/Montserrat-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('@/assets/fonts/Montserrat-Bold.ttf') format('ttf'),
        url('@/assets/fonts/Montserrat-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}

/* Apply Montserrat font to the body */
body {
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 24px;
}
</style>

<style scoped>
.customer-styles{
  background-color: #f0fffb;
  
  @media (min-width: 960px){
    max-width: 100vw;
    min-height: 90vh;
    display: flex;
    align-items: center;
    align-content: space-between;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
