<template>
  <v-layout class="custom-color">
    <v-container>
      <div class="d-none d-md-flex">
        <v-app-bar color="teal-darken-4"
                    image="assets/bg_photos/bg_photo_3.jpg"
                    dark 
                    shrink-on-scroll 
                    prominent>
          <v-container>
            <router-link to="/" class="header-new">About</router-link>
            <router-link to="/icon" class="header-new">Icons</router-link>
            <router-link to="/church-painting" class="header-new"
              >Church painting</router-link
            >
            <router-link to="/social-activities" class="header-new">Social Activities</router-link>
            <router-link to="/contact" class="header-new">Contacts</router-link>
          </v-container>
        </v-app-bar>
      </div>
      <!-----------------mobile-------------------->
      <div
        class="d-flex d-md-none justify-space-between align-center my-4 mx-2"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" 
                            size="x-large"
                            color="white"></v-app-bar-nav-icon>
        <v-navigation-drawer v-model="drawer" 
                              location="start" 
                              temporary>
          <v-btn variant="plain" @click.stop="drawer = false">
            <v-icon>mdi-close-outline</v-icon>
          </v-btn>
          <v-list nav density="compact">
            <v-list-item>
              <template v-slot:prepend>
                <v-icon>mdi-drawing-box</v-icon>
              </template>
              <v-list-item-title>
                <router-link to="/" class="header-new">About</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <template v-slot:prepend>
                <v-icon>mdi-palette</v-icon>
              </template>
              <v-list-item-title>
                <router-link to="/icon" class="header-new">Icons</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <template v-slot:prepend>
                <v-icon>mdi-brush</v-icon>
              </template>
              <v-list-item-title>
                <router-link to="/church-painting" class="header-new"
                  >Church painting</router-link
                >
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <template v-slot:prepend>
                <v-icon>mdi-bell-ring</v-icon>
              </template>
              <v-list-item-title>
                <router-link to="/social-activities" class="header-new">Social Activities</router-link>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <template v-slot:prepend>
                <v-icon>mdi-card-account-mail</v-icon>
              </template>
              <v-list-item-title>
                <router-link to="/contact" class="header-new"
                  >Contacts</router-link
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </div>
      <v-container class="d-none d-md-flex" style="height: 8vh"></v-container>
    </v-container>
  </v-layout>
</template>

<script setup>
  import { ref } from 'vue';

  const drawer = ref(false);
</script>

<script>
export default {
  name: 'NavBar'
};
</script>



<style scoped>
.custom-color {
  background-image: url('@/assets/bg_photos/bg_photo_3.jpg');
  background-size: cover; 
  background-position: center; 

  @media (min-width: 1200px) {
    height: 5.67rem;
  }
}

.avatar-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: aliceblue;
}
.v-application a {
  color: rgb(85 34 150) !important;
  text-decoration: none !important;

  @media (min-width: 1200px) {
    color: aliceblue !important;
    text-decoration: none !important;
  }
}

.header-new {
  margin-right: 4rem;
  text-decoration: none;
  color: unset;
}

.header-new.router-link-active {
  color: black !important;
}

.user-info {
  display: flex;
  align-items: center;

  @media (min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 60vw;
  }
}

::v-deep .v-list-item__title {
  font-size: 1rem !important;
}

::v-deep .v-toolbar__content {
  justify-content: space-between;
  height: 90px !important;
}

::v-deep .v-toolbar {
  height: 90px !important;
}

::v-deep .v-navigation-drawer__content {
  margin-top: 1rem;
}

::v-deep .v-navigation-drawer--active {
  top: 0px !important;
  height: 100vh !important;
}

::v-deep button {
  float: right;
  margin-right: 0.5rem;
}

::v-deep .v-list--nav {
  margin-top: 2rem;
}

::v-deep .v-ripple__container {
  border-radius: 50% !important;
  /* width: 50px!important; */
}

::v-deep .v-app-bar.v-toolbar {
  box-shadow: 0;
}

::v-deep .v-list-item--nav .v-list-item-title {
  font-size: 16px;
  line-height: 2rem;
}

::v-deep .v-list-item--density-compact.v-list-item--one-line {
  min-width: 240px;
}

::v-deep .v-list-item__prepend > .v-icon {
  font-size: 24px;
}
</style>
