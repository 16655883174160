export const homeViewInfo = [
 {
  title: 'Exhibition of Ukrainian Icon Painter Ksenia Bludova',
  subtitle: 'The Museum of Coins and Medals of John Paul II in Częstochowa, May 15, 2024', 
  photos: [
   {
    src: 'home_view/home_view_1.jpg',
    alt: 'Ksenia Bludova'
   },
   {
    src: 'home_view/home_view_2.jpg',
    alt: 'Invitation on Exebition Ksenii Bludovej'
   },
   {
    src: 'home_view/home_view_3.jpg',
    alt: 'Ksenia Bludova in Częstochowa'
   },
   {
    src: 'home_view/home_view_4.jpg',
    alt: 'Ksenia Bludova in The Museum of Coins and Medals of John Paul II'
   },
   {
    src: 'home_view/home_view_5.jpg',
    alt: 'Ukrainian Icon Painter Ksenia Bludova'
   },
   {
    src: 'home_view/home_view_6.jpg',
    alt: 'Icon of Ksenia Bludova'
   },
   {
    src: 'home_view/home_view_7.jpg',
    alt: 'Icon of Ksenia Bludova'
   },
   {
    src: 'home_view/home_view_8.jpg',
    alt: 'Icon of Ksenia Bludova'
   },
   {
    src: 'home_view/home_view_9.jpg',
    alt: 'Icon of Ksenia Bludova'
   },
   {
    src: 'home_view/home_view_10.jpg',
    alt: 'Ukrainian Icon Painter Ksenia Bludova with Director of The Museum of Coins and Medals of John Paul II in Częstochowa'
   },
   {
    src: 'home_view/home_view_11.jpg',
    alt: 'Icon of Ksenia Bludova'
   },
   {
    src: 'home_view/home_view_12.jpg',
    alt: 'Icon of Ksenia Bludova'
   },
   {
    src: 'home_view/home_view_14.jpg',
    alt: 'Icon of Ksenia Bludova'
   },
   {
    src: 'home_view/home_view_15.jpg',
    alt: 'Icon of Ksenia Bludova'
   },
   {
    src: 'home_view/home_view_16.jpg',
    alt: 'Icon of Ksenia Bludova'
   }
  ]
 }
]